<template>
  <div class="tariff-info">
    <feather-icon
      icon="DiscIcon"
      :class="getTariffLeft > 0 ? 'tariff-active' : 'tariff-expired'"
      class="mr-1"
    ></feather-icon>
    Тариф -
    <b-nav-item href="/pages/pricing">
      <span class="text-primary">
        {{ getTariff }}
      </span>
    </b-nav-item>
  </div>
</template>

<script>
import { BNavItem } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BNavItem,
  },
  computed: {
    ...mapGetters("user", ["getTariffLeft", "getTariff"]),
  },
  methods: {
    ...mapActions("user", ["requestUserData"]),
    declOfNum(number, words) {
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
      ];
    },
  },
  created() {
    this.requestUserData();
  },
};
</script>

<style lang="scss">
.tariff-info {
  display: flex;
  align-items: center;
}
.tariff-active {
  color: green;
}
.tariff-expired {
  color: red;
}
</style>
