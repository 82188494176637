import { getUserData } from '@/auth/utils'

const isAdmin = getUserData().role === 'admin';
let adminPanel = [];

if (isAdmin) {
  adminPanel = [
    {
      header: 'Админ панель',
    },
    {
      title: 'Пользователи',
      route: 'admin-users',
      icon: 'UsersIcon'
    },
    {
      title: 'Список проектов',
      route: 'admin-projects',
      icon: 'PlayIcon'
    },
    {
      title: 'Список Лид-форм',
      route: 'admin-lead-forms',
      icon: 'FileIcon'
    },
    {
      title: 'Список заявок',
      route: 'admin-answers',
      icon: 'InboxIcon'
    },
    {
      title: 'Список интеграций',
      route: 'admin-integrations',
      icon: 'Link2Icon'
    },
    {
      title: 'Общая статистика',
      route: 'admin-statistics',
      icon: 'BarChartIcon',
      disabled: true,
    },
  ]
} else {
  adminPanel = [];
}

export default adminPanel;