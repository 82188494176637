export default [{
    header: 'Квесты',
  },
  {
    title: 'Проекты',
    route: 'projects',
    icon: 'PlayIcon',
  },
  {
    title: 'Лид-формы',
    route: 'lead-forms',
    icon: 'FileTextIcon',
  },
  {
    title: 'Заявки',
    route: 'answers',
    icon: 'InboxIcon',
  },
  {
    title: 'Статистика',
    route: 'project-statistics',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Интеграции',
    route: 'integrations',
    icon: 'Link2Icon',
  }
]